import { Fragment, useState } from "react"

import {
  SmokeFeedbackDialog,
  TSmokeEventFeedbackData,
} from "src/components/EventLog/SmokeFeedbackDialog"
import {
  HomeCell,
  HomeLogBodyCell,
  HomeLogDeviceCell,
} from "src/components/HomeLog/HomeLogContent"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { usePostCigaretteReportFindingsClicked } from "src/data/analytics/queries/smokingDetectionAnalyticQueries"
import {
  hasDeviceMetaData,
  THomeLogEvent,
} from "src/data/events/types/homeEventTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { GridTable } from "src/ui/GridTable/GridTable"
import { LocalizedDate } from "src/ui/LocalizedDate/LocalizedDate"

type HomeLogTableGlobalProps = {
  homeEvents: THomeLogEvent[]
  loading?: boolean
}

export function HomeLogTableGlobal({
  homeEvents,
  loading,
}: HomeLogTableGlobalProps) {
  const { t, langKeys } = useTranslate()

  const [showSmokeFeedbackForm, setShowSmokeFeedbackForm] = useState<
    (TSmokeEventFeedbackData & { open: boolean }) | null
  >(null)
  const postCigaretteReportFindingsClicked =
    usePostCigaretteReportFindingsClicked()

  const header = [
    <div key={"event"}>{t(langKeys.event)}</div>,
    <div key={"sensor"}>{t(langKeys.sensor)}</div>,
    <div key={"home"}>{t(langKeys.home)}</div>,
    <div key={"time"}>{t(langKeys.time)}</div>,
  ]

  function handleSmokeFeedbackClicked(e: THomeLogEvent) {
    if (!hasDeviceMetaData(e.metadata.event_specific_data)) return
    if (!e.event_id) return

    postCigaretteReportFindingsClicked.mutate({
      context: "event_log",
    })
    setShowSmokeFeedbackForm({
      open: true,

      homeId: e.home_id,
      date: e.event_time,
      timezone: e.metadata.timezone,
      eventId: e.event_id,
      deviceId: e.metadata.event_specific_data.device_id,
    })
  }

  const rows = homeEvents.map((event) => {
    // Unfortunately some events don't have an event_id, so we're falling back
    // to a unique random key. It will trigger an unnecessary re-render for
    // these events, but it's better than not correctly removing them from the
    // list when the user is e.g. filtering or paging through the list.
    const key = event.event_id ?? Math.random()

    return (
      <Fragment key={key}>
        <HomeLogBodyCell
          event={event}
          onSmokeFeedbackClicked={handleSmokeFeedbackClicked}
        />

        <HomeLogDeviceCell data={event.metadata?.event_specific_data} />

        <HomeCell homeId={event.home_id} homeName={event.metadata.home_name} />

        <LocalizedDate
          date={event.event_time}
          timezone={event.metadata.timezone}
        />
      </Fragment>
    )
  })

  return (
    <div>
      <GridTable
        header={header}
        rows={rows}
        templateColumns="1fr auto auto auto"
        loading={loading}
        useFallbackResponsiveMode
      />

      <IncidentReportDialogContainer context="event_log" />

      <SmokeFeedbackDialog
        open={!!showSmokeFeedbackForm?.open}
        onClose={() =>
          setShowSmokeFeedbackForm((d) =>
            d?.homeId ? { ...d, open: false } : null
          )
        }
        context="home_log"
        feedbackData={showSmokeFeedbackForm}
        homeEventFeedback
      />
    </div>
  )
}
